import { render, staticRenderFns } from "./BackupCodes.vue?vue&type=template&id=b6ee12ae&scoped=true&"
import script from "./BackupCodes.vue?vue&type=script&lang=js&"
export * from "./BackupCodes.vue?vue&type=script&lang=js&"
import style0 from "./BackupCodes.vue?vue&type=style&index=0&id=b6ee12ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6ee12ae",
  null
  
)

export default component.exports